import { navigate } from "gatsby";
import { saveState } from "./localStorage";

export const handleAuth0Error = (error) => {
  const errorParams = new URLSearchParams({
    error: error.error || "unknown_error",
    error_description: error.error_description || error.message,
    state: error.state,
  }).toString();

  navigate(`/auth-error?${errorParams}`);
};

export const loginWithAuth0 = (loginWithRedirect, href = "/member") => {
  try {
    saveState("redirectUrl", href);
    return loginWithRedirect({
      authorizationParams: {
        ui_locales: "en",
        redirect_uri: `${window.location.origin}/callback`,
      },
      appState: { target: "redirectUrl" },
    });
  } catch (error) {
    handleAuth0Error(error);
  }
};
